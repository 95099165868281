import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import infiniteScroll from 'vue3-infinite-scroll-good'
import './assets/css/reset.scss'
import { create, NConfigProvider, NButton, NCard, NDatePicker,
  NInput, NSelect, NLayout, NLayoutHeader, NSpace, useMessage,
  NMessageProvider, NInputGroup, NIcon, NSwitch} from 'naive-ui'
const naive = create({
  components: [NConfigProvider, NButton,
    NCard, NDatePicker, NInput, NSelect, NLayout,
    NLayoutHeader, NSpace, NMessageProvider, NInputGroup, NIcon, NSwitch]
})
const message = useMessage()
const app = createApp(App)
app.use(store).use(router).use(naive).use(infiniteScroll).mount('#app')
app.config.globalProperties.$message = message
