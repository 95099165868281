import { createStore } from 'vuex'
import { connectWallet, contractFun } from '../lib/connectWallet'
import { contractData, contractData_main } from '../lib/contract'
import { ethers } from 'ethers'
import {chains} from '../lib/chains'
export default createStore({
  state: {
    web3: null,
    address: "",
    chains: chains,
    balance: '',
    network: null,
    activeId: '',
    menuList: [],
    contractList: [],
    openSols: [],
    contract: null
  },
  mutations: {
    setOpenSols(state, openSols) {
      state.openSols = openSols
    },
    setMenuList(state, menuList) {
      state.menuList = menuList
    },
    setContractList(state, contractList) {
      state.contractList = contractList
    },
    setActiveId(state, id) {
      state.activeId = id
    },
    setChains(state, chains) {
      state.chains = chains
    },
    async getWeb3(state) {
      console.log(1)
      let web3 = await connectWallet()
      let accounts = await web3.listAccounts()
      let wallet_address = ''
      let balanceInEth = 0

      if (accounts.length) {
        wallet_address = accounts[0]
        try {
          let balance = await web3.getBalance(wallet_address)
          balanceInEth = ethers.utils.formatEther(balance);
        } catch (error) {
          console.log(error)
        }
      }
      let user = web3.getSigner()
      let network = await web3.getNetwork()
      let contract
      if (network.chainId == 1) {
        contract = await contractFun(contractData_main.address, contractData_main.abi, user)
      } else {
        contract = await contractFun(contractData.address, contractData.abi, user)
      }
      console.log(network)
      state.web3 = web3
      state.balance = balanceInEth
      state.address = wallet_address
      let networkData = state.chains.filter(e => network.chainId == e.chainId)[0]
      network.name = networkData.name
      state.network = network
      state.contract = contract

    },
    setAddress(state, address) {
      state.address = address
    },
    setBalance(state, balance) {
      state.balance = balance
    }
  },
  actions: {
  },
  modules: {
  }
})
