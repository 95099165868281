<template>
  <n-layout-header class="header" style="padding: 0 24px">
    <div class="logo" >Kakashi Sensei</div>
    <div class="header-r">
      <div style="display: flex;align-items: center;position: relative;">
        <svg t="1660392975370" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2493" width="18" height="18"><path d="M929.1 637.6c-16.1-36-10.4-73.2-4.9-109.1 8.5-55.6 18.8-123.7-69.7-163.5 5.9-21.2 5.4-45.6-12.5-54.2l-105.3-50.7c-23-11.1-83-8.7-90.1 5.9-7 14.6-26.4 45 22.8 56.3s95 68.9 95 68.9c16.5 16.1 35.5 25.7 50.4 25.9 59 20.7 55.7 44.9 47 101.8-6.3 40.7-14 91.5 9.7 144.5 32 71.4 33.9 113.4 6.3 140.4-33.8 33.3-79.5 22.7-108.3 3.6-38.9-25.8-39.5-73.5-36-149.1 2-42.8 4.1-87-8.5-124.7C696.9 450 611 449 607 449h-38.2V164.9c0-17.4-14.2-31.6-31.6-31.6H158.7c-17.3 0-31.6 14.2-31.6 31.6v662.6H95.6s-31.6 0-31.6 31.6v31.6h568.2v-31.6s0-31.6-31.6-31.6H569V512h37.9c4.6 0.1 45.2 2 58.5 41.6 8.9 26.5 7.1 63 5.3 101.7-3.5 73.7-7.4 157.2 64.1 204.6 28.7 19 60 28.4 90.3 28.4 35.9 0 70.4-13.3 97.1-39.5 70.4-69.2 24.2-172.3 6.9-211.2zM785.4 376c-8.7 0-15.8-7.1-15.8-15.8 0-8.7 7.1-15.8 15.8-15.8s15.8 7.1 15.8 15.8-7 15.8-15.8 15.8z m-279.7 41.3c0 17.3-14.2 31.6-31.6 31.6H221.8c-17.3 0-31.6-14.2-31.6-31.6V228c0-17.3 14.2-31.6 31.6-31.6h252.4c17.3 0 31.6 14.2 31.6 31.6v189.3h-0.1z" p-id="2494" fill="#2c2c2c"></path></svg>
        <span style="margin-left: 4px;margin-right:20px">{{gasPrice}}</span>
        <div class="progress">
          <!-- <el-progress :percentage="20 * progress" :show-text="false"></el-progress> -->
        </div>
      </div>
      <!-- <div class="chain">
        <n-select 
          :value="network.chainId"
          :options="chains"
          label-field="name"
          value-field="chainId"
          :virtual-scroll="true"
          style="width: 200px"
          @update:value="handleChange"
        />
      </div> -->
      <div class="user-info">
        <div class="balance">{{balance}} {{coinName}}</div>
        <div class="address">
          <span @click="isShowDialog=true">{{address}}</span>
          <Jazzicon 
            v-if="address"
            class="jazzicon"
            :diameter="16" 
            :address="fullAddress"
          />
        </div>
      </div>
      <div class="setting">
        <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="Menu__StyledMenuIcon-sc-1s7r6xc-0 eQiQbQ"><path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg> -->
        <!-- <div class="setting-btn" @click="changeLocale">{{$i18n.locale == 'en' ? '中文' : 'Engilsh'}}</div> -->
      </div>
    </div>
    <div class="dialog" v-if="isShowDialog">
      <div class="dialog-content">
        <div class="dialog-hd">
          <div class="dialog-title">账户</div>
          <div class="dialog-close" @click="isShowDialog=false">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="AccountDetails__CloseColor-sc-75okty-10 loWQUb"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
          </div>
        </div>
        <div class="dialog-bd">
          <div class="user-info">
            <div class="address">
              <Jazzicon 
                v-if="address"
                class="jazzicon"
                :diameter="16" 
                :address="fullAddress"
              />
              <span >{{address}}</span>
            </div>
          </div>
          <div class="operation">
            <div class="copy" @click="copy">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
              <span>复制地址</span>
            </div>
            <div>
              <a :href="'https://rinkeby.etherscan.io/address/'+fullAddress" class="to-etherscan" target="_blank" >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                <span>在以太坊资源浏览器查看</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </n-layout-header>
</template>

<script>
import { ethers } from 'ethers'
import Jazzicon from './Jazzicon'
import {walletSwitchChain} from '../lib/walletSwitchChain'
import { defineComponent, computed, watch, ref, toRaw } from "vue";
import { useStore } from 'vuex'
import { useMessage } from 'naive-ui'
let timeout
export default defineComponent({
  name: 'Header',
  components: {
    Jazzicon: Jazzicon
  },
  setup() {
    const message = useMessage()
    const store = useStore()
    const chains = computed(() => {
      console.log(store.state.chains)
      return store.state.chains
    })
    const gasPrice = ref(0)
    const coinName = ref('')
    const chainId = ref('')
    const isShowDialog = ref(false)
    const balance = computed(() => {
      let balance = store.state.balance || 0
      return balance = (+balance).toFixed(2)
    })
    const fullAddress = computed(() => {
      let address = store.state.address
      return address
    })
    const address = computed(() => {
      let address = store.state.address
      return address ? `${address.slice(0, 6)}...${address.slice(-4)}` : ''
    })
    const network = computed(() => {
      return store.state.network || {}
    })
    const web3 = computed(() => {
      return store.state.web3
    })

    watch(web3, async (val) => {
      console.log(val)
      if (val) {
        getGasPrice()
      }
    }, {immediate: true})
    watch(network, (val) => {
      console.log(val)
      let network = val
      let chains = store.state.chains
      let chain = chains.filter(e => e.chainId == network.chainId)[0]
      let cn = chain && chain.chain || ''
      if (cn == 'ETH') {
        if (chain.network && chain.network == 'testnet') {
          cn = `${chain.name[0].toLocaleLowerCase()}ETH`
        } else {
          cn = `ETH`
        }
      }
      coinName.value = cn
    })

    const getGasPrice = async () => {
      let gp = await toRaw(web3.value).getGasPrice()
      gasPrice.value = Math.floor(ethers.utils.formatUnits(gp, "gwei"))
      console.log(gasPrice.value)
    }

    const copy = () => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(fullAddress.value)
        message.success('已成功复制到剪切板')
        isShowDialog.value = false
      }
    }

    const handleChange = (e) => {
      console.log(e)
      chainId.value = e
      walletSwitchChain(e).then(() => {
        store.commit("getWeb3")
      })
    }

    return {
      handleChange,
      copy,
      chains,
      balance,
      fullAddress,
      address,
      network,
      web3: web3,
      gasPrice,
      coinName,
      chainId,
      isShowDialog
    };
  },
  async mounted() {
    console.log(this.$store.state)
    if (this.web3) {
      clearTimeout(timeout)
      this.progress = 0
    }
  },
  beforeUnmount() {
    clearTimeout(timeout)
  },
  methods: {
    // getProgress() {
    //   if (this.progress == 5) {
    //     this.getGasPrice()
    //     return
    //   } else {
    //     this.progress += 1
    //   }
    //   timeout = setTimeout(() => {this.getProgress()}, 1000)
    // },
  },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: rgba(0, 0 ,0, .08) 0px 0px 5px;
  position: relative;
  z-index: 99;
  height: 72px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  .logo {
    font-size: 28px;
    font-weight: 550;
    font-family: Baskerville;
  }
  .header-r {
    display: flex;
    align-items: center;
    .user-info {
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 2px;
      box-sizing: border-box;
      box-shadow: rgba(0, 0 ,0, .08) 0px 0px 5px;
      border-radius: 20px;
      height: 40px;
      margin-left: 20px;
      margin-right: 20px;
      .balance {
        height: 40px;
        display: flex;
        align-items: center;
        margin-right: 8px;
      }
      .address {
        font-size: 0;
        display: flex;
        align-items: center;
        height: 40px;
        background-color: #F7F8FA;
        border-radius: 20px;
        padding: 0 8px;
        box-sizing: border-box;
        height: 36px;
        span {
          font-size: 14px;
          height: 36px;
          line-height: 36px;
          margin-right: 6px;
          cursor: pointer;
        }
        .jazzicon {
          height: 36px;
          display: flex;
          align-items: center;
        }
      }
    }
    .progress {
      position: absolute;
      bottom: -10px;
      left: -10px;
      right: 0;
      width: 100%;
    }
    .setting {
      display: flex;
      align-items: center;
      padding: 0 10px;
      height: 40px;
      border-radius: 5px;
      .setting-btn {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        box-shadow: rgba(0, 0 ,0, .08) 0px 0px 3px;
        cursor: pointer;
      }
    }
  }
  .dialog {
    .user-info {
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 2px;
      box-sizing: border-box;
      border-radius: 20px;
      height: 40px;
      margin-left: 20px;
      margin-right: 20px;
      .address {
        font-size: 0;
        display: flex;
        align-items: center;
        height: 40px;
        border-radius: 20px;
        padding: 0 8px;
        box-sizing: border-box;
        height: 36px;
        span {
          font-size: 14px;
          height: 36px;
          line-height: 36px;
          margin-right: 6px;
          cursor: pointer;
        }
        .jazzicon {
          height: 36px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
.dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, .4);
  display: flex;
  align-items: center;
  justify-content: center;
  .dialog-content {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    width: 400px;
    .dialog-hd {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .dialog-title {
        font-size: 16px;
        box-sizing: border-box;
        font-weight: 500;
      }
      .dialog-close {
        cursor: pointer;
      }
    }
    .dialog-bd {
      border: 1px solid rgb(206, 208, 217);
      box-sizing: border-box;
      margin-top: 16px;
      padding: 16px;
      border-radius: 10px;
    }
    .user-info {
      margin: 0;
      padding: 0;
      .address {
        padding: 0;
        .jazzicon {
          margin-right: 8px;
        }
        span {
          color: rgb(0, 0, 0);
          font-weight: 500;
          font-size: 18px;
          box-sizing: border-box;
        }
      }
    }
    .operation {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .to-etherscan {
        margin-left: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .copy {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      span {
        font-weight: 500;
        color: rgb(110, 114, 125);
        font-size: 12px;
        box-sizing: border-box;
        margin-left: 4px;
      }
    }
  }
}
</style>
<style lang="scss">
</style>
