import axios from 'axios';

let baseURL = 'https://api.shifuka.com'
axios.defaults.timeout = 90000;

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export const post = (url, data = {}) => {
  console.log(data)
  url = `${baseURL}${url}`
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(response => {
      if (response.data.errors) {
        reject(response.data)
        return
      }
      resolve(response.data);
    }, err => {
      reject(err)
    })
  })
}

export const get = (url, params = {}) => {
  url = `${baseURL}${url}`
  return new Promise((resolve,reject) => {
    axios.get(url, {params}).then(response => {
      resolve(response.data)
    }).catch(err => {
      reject(err)
    })
  })
}
