<template>
  <div class="home">
    <nav-space class="content">
      <div class="hd">
        <img src="@/assets/images/kakaxi.png" alt="">
      </div>
      <div class="bd">
        <div class="bd-l">
          <div v-if="showSearchLoading && !next" class="loading-search">
            <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>
          <n-input-group>
            <n-select :style="{ width: '20%' }" v-model:value="selectValue" :options="selectOptions" />
            <n-input :style="{ width: '90%' }" v-model:value="searchValue" @keyup.enter="getNft" />
            <n-button :style="{ width: '10%' }" @click="getNft">scan</n-button>
          </n-input-group>
          <div class="nft-title">
            <span>{{resultValue && !nftsOfMe ? 'NFT of ' + resultValue : 'NFT of me'}}</span>
            <div class="nft-switch">
              <span>Hide Kakashi NFT：</span> 
              <n-switch size="medium" v-model:value="switchValue" />
            </div>
          </div>
          <div class="nfts-wrap">
            <div class="nfts" v-if="!showSearchLoading && nfts.length">
              <div class="nft-item" v-for="(item, index) in filterNfts()" :key="index" @click="selectNft(item)">
                <img :src="item.image" alt="">
              </div>
              <div class="nft-item" style="height:0;margin:0"></div>
              <div class="nft-item" style="height:0;margin:0"></div>
              <div class="nft-item" style="height:0;margin:0"></div>
              <div class="nft-item" style="height:0;margin:0"></div>
            </div>
            <div v-if="!showSearchLoading && !nfts.length">
              <p style="text-align:center;margin-top:52px;color:rgba(0, 0, 0, .5)">Not Found</p>
            </div>
          </div>
        </div>
        <div class="bd-r">
          <div>
            <div class="bd-r-hd">
              <img v-if="!loading" src="@/assets/images/rghfgg.jpeg" alt="">
              <img v-if="loading" src="@/assets/images/kakaxi.gif" alt="">
            </div>
            <div v-if="nftData && nftData.contract_address" class="bd-r-bd">
              <img :src="nftData.image" alt="">
              <div class="bd-r-bd-r">
                <p class="address">Address: {{formatAddress(nftData.contract_address)}}
                  <svg @click="copy(nftData.contract_address)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16"><g fill="none"><path d="M4 4.085V10.5a2.5 2.5 0 0 0 2.336 2.495L6.5 13h4.414A1.5 1.5 0 0 1 9.5 14H6a3 3 0 0 1-3-3V5.5a1.5 1.5 0 0 1 1-1.415zM11.5 2A1.5 1.5 0 0 1 13 3.5v7a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 5 10.5v-7A1.5 1.5 0 0 1 6.5 2h5zm0 1h-5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5z" fill="currentColor"></path></g></svg>
                </p>
                <p class="token-id">tokenId: {{formatTokenId(nftData.token_id)}}
                  <svg @click="copy(nftData.token_id)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16"><g fill="none"><path d="M4 4.085V10.5a2.5 2.5 0 0 0 2.336 2.495L6.5 13h4.414A1.5 1.5 0 0 1 9.5 14H6a3 3 0 0 1-3-3V5.5a1.5 1.5 0 0 1 1-1.415zM11.5 2A1.5 1.5 0 0 1 13 3.5v7a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 5 10.5v-7A1.5 1.5 0 0 1 6.5 2h5zm0 1h-5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5z" fill="currentColor"></path></g></svg>
                </p>
                <n-button v-if="nftData.openseaUrl"><a :href="nftData.openseaUrl" target="_blank">Click to view</a></n-button>
              </div>
            </div>
            <div class="bd-ft">
              <n-button :disabled="loading || !nftData.contract_address" strong secondary style="width:320px;margin-top:12px" @click="kakashi">{{nftData && nftData.contract_address ? 'Please! Kakashi Sensei' : 'Please select a NFT'}}</n-button>
            </div>
          </div>
        </div>
      </div>
    </nav-space>
    <div class="link">
      <n-button ghost color="#18a058" class="link-btn">
        view contact on DappReader
        <svg t="1661059289333" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3129" width="24" height="24"><path d="M341.333333 512a42.666667 42.666667 0 0 0 42.666667 42.666667h256a42.666667 42.666667 0 0 0 0-85.333334H384a42.666667 42.666667 0 0 0-42.666667 42.666667z" p-id="3130" fill="#18a058"></path><path d="M384 682.666667H307.626667A176.213333 176.213333 0 0 1 128 527.786667 170.666667 170.666667 0 0 1 298.666667 341.333333h85.333333a42.666667 42.666667 0 0 0 0-85.333333H307.626667a262.4 262.4 0 0 0-262.826667 222.293333A256 256 0 0 0 298.666667 768h85.333333a42.666667 42.666667 0 0 0 0-85.333333zM981.333333 479.573333A262.826667 262.826667 0 0 0 715.093333 256h-64.426666C616.106667 256 597.333333 275.2 597.333333 298.666667a42.666667 42.666667 0 0 0 42.666667 42.666666h76.373333A176.213333 176.213333 0 0 1 896 496.213333 170.666667 170.666667 0 0 1 725.333333 682.666667h-85.333333a42.666667 42.666667 0 0 0 0 85.333333h85.333333a256 256 0 0 0 256-288.426667z" p-id="3131" fill="#18a058"></path></svg>
      </n-button>
    </div>
    <div v-if="showTost" class="tost-loading">
      <div class="tost-card">
        <div class="tost-card-hd">
          <div class="tost-card-title">{{!loading ? 'Ninjas Success!' : 'Ninjas Pending!'}}</div>
          <img v-if="!loading" src="@/assets/images/kakaxi_success.gif" alt="">
          <img v-if="loading" src="@/assets/images/kakaxi.gif" alt="">
        </div>
        <div class="tost-card-bd">
          <img :src="nftData.image" alt="">
          <div class="tost-card-bd-r">
            <div v-if="loading">
              <p class="address">Address: {{formatAddress(nftData.contract_address)}}
                <svg @click="copy(nftData.contract_address)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16"><g fill="none"><path d="M4 4.085V10.5a2.5 2.5 0 0 0 2.336 2.495L6.5 13h4.414A1.5 1.5 0 0 1 9.5 14H6a3 3 0 0 1-3-3V5.5a1.5 1.5 0 0 1 1-1.415zM11.5 2A1.5 1.5 0 0 1 13 3.5v7a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 5 10.5v-7A1.5 1.5 0 0 1 6.5 2h5zm0 1h-5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5z" fill="currentColor"></path></g></svg>
              </p>
              <p class="token-id">tokenId: {{formatTokenId(nftData.token_id)}}
                <svg @click="copy(nftData.token_id)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16"><g fill="none"><path d="M4 4.085V10.5a2.5 2.5 0 0 0 2.336 2.495L6.5 13h4.414A1.5 1.5 0 0 1 9.5 14H6a3 3 0 0 1-3-3V5.5a1.5 1.5 0 0 1 1-1.415zM11.5 2A1.5 1.5 0 0 1 13 3.5v7a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 5 10.5v-7A1.5 1.5 0 0 1 6.5 2h5zm0 1h-5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5z" fill="currentColor"></path></g></svg>
              </p>
            </div>
            <div v-else>
              <p class="address">OriginAddress: {{formatAddress(nftData.contract_address)}}
                <svg @click="copy(nftData.contract_address)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16"><g fill="none"><path d="M4 4.085V10.5a2.5 2.5 0 0 0 2.336 2.495L6.5 13h4.414A1.5 1.5 0 0 1 9.5 14H6a3 3 0 0 1-3-3V5.5a1.5 1.5 0 0 1 1-1.415zM11.5 2A1.5 1.5 0 0 1 13 3.5v7a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 5 10.5v-7A1.5 1.5 0 0 1 6.5 2h5zm0 1h-5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5z" fill="currentColor"></path></g></svg>
              </p>
              <p class="address">NewAddress: {{formatAddress(nftData.new_contract_address)}}
                <svg @click="copy(nftData.new_contract_address)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16"><g fill="none"><path d="M4 4.085V10.5a2.5 2.5 0 0 0 2.336 2.495L6.5 13h4.414A1.5 1.5 0 0 1 9.5 14H6a3 3 0 0 1-3-3V5.5a1.5 1.5 0 0 1 1-1.415zM11.5 2A1.5 1.5 0 0 1 13 3.5v7a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 5 10.5v-7A1.5 1.5 0 0 1 6.5 2h5zm0 1h-5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5z" fill="currentColor"></path></g></svg>
              </p>
              <p class="address">Hash: {{formatAddress(nftData.hash)}}
                <svg @click="copy(nftData.hash)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16"><g fill="none"><path d="M4 4.085V10.5a2.5 2.5 0 0 0 2.336 2.495L6.5 13h4.414A1.5 1.5 0 0 1 9.5 14H6a3 3 0 0 1-3-3V5.5a1.5 1.5 0 0 1 1-1.415zM11.5 2A1.5 1.5 0 0 1 13 3.5v7a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 5 10.5v-7A1.5 1.5 0 0 1 6.5 2h5zm0 1h-5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5z" fill="currentColor"></path></g></svg>
              </p>
              <p class="token-id">tokenId: {{formatTokenId(nftData.token_id)}}
                <svg @click="copy(nftData.token_id)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16"><g fill="none"><path d="M4 4.085V10.5a2.5 2.5 0 0 0 2.336 2.495L6.5 13h4.414A1.5 1.5 0 0 1 9.5 14H6a3 3 0 0 1-3-3V5.5a1.5 1.5 0 0 1 1-1.415zM11.5 2A1.5 1.5 0 0 1 13 3.5v7a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 5 10.5v-7A1.5 1.5 0 0 1 6.5 2h5zm0 1h-5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5z" fill="currentColor"></path></g></svg>
              </p>
            </div>
          </div>
        </div>
        <div class="tost-card-ft">
          <n-button :disabled="loading" strong secondary style="width:320px;margin-top:12px" @click="showTost = false">{{loading ? 'Ninjas Pending' : 'Thank you Kakashi'}}</n-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNfts, getContract } from '../http/api'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router';
import { useMessage } from 'naive-ui'
import { ethers } from 'ethers'
import { useStore } from 'vuex'
import { contractData } from '../lib/contract'
import { walletSwitchChain } from '../lib/walletSwitchChain'

export default {
  name: 'Home',
  components: {},
  setup() {
    const router = useRouter()
    const message = useMessage()
    const store = useStore()
    const nftData = ref({})
    const nfts = ref([])
    const nftsOfMe = ref(true)
    const switchValue = ref(true)
    const loading = ref(false)
    const showSearchLoading = ref(false)
    const showTost = ref(false)
    const next = ref(null)
    const busy = ref(false)
    const pageSize = ref(180)
    const selectValue = ref(1)
    const resultValue = ref('')
    const searchValue = ref('')
    console.log(router.currentRoute.value)
    const query = router.currentRoute.value.query
    if (query.contract) {
      searchValue.value = query.contract
      selectValue.value = 2
    } else if (query.wallet) {
      searchValue.value = query.wallet
      selectValue.value = 1
    }
    const formatAddress = computed(() => {
      return (address) => {
        return address ? `${address.slice(0, 6)}...${address.slice(-4)}` : ''
      }
    })
    const formatTokenId = computed(() => {
      return (tokenId) => {
        return (tokenId && tokenId.length > 16) ? `${tokenId.slice(0, 6)}...${tokenId.slice(-4)}` : tokenId
      }
    })
    const filterNfts = computed(() => {
      console.log(contractData.address)
      return () => {
        return switchValue.value ? nfts.value.filter(e => e.contract_address.toLocaleLowerCase() != contractData.address.toLocaleLowerCase()) : nfts.value
      }
    })
    const contract = computed(() => {
      let contract = store.state.contract
      return contract
    })
    const network = computed(() => {
      let network = store.state.network
      return network
    })
    const address = computed(() => {
      let address = store.state.address
      return address
    })
    const copy = (value) => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(value)
        message.success('Copy successful')
      }
    }
    const selectNft = (item) => {
      nftData.value = item
    }
    const kakashi = async () => {
      if (network.value.chainId != 4 && network.value.chainId != 1) {
        await walletSwitchChain(1)
        return
      }
      loading.value = true
      showTost.value = true
      try {
        let tx = await contract.value.Mudras(nftData.value.contract_address, nftData.value.token_id, { from: address.value})
        const receipt = await tx.wait()
        console.log(receipt)
        let events = receipt.events
        let event = events.filter(e => e.event == 'MudrasSuccess')
        if (event && event.length) {
          event = event[0]
          let eventValue = ethers.utils.formatUnits(event.args[0], "wei")
          nfts.value.forEach(e => {
            if (e.token_id == nftData.value.token_id && e.contract_address == nftData.value.contract_address) {
              e.openseaUrl = `https://testnets.opensea.io/assets/rinkeby/${contractData.address}/${eventValue}`
            }
          })
          nftData.value.openseaUrl = `https://testnets.opensea.io/assets/rinkeby/${contractData.address}/${eventValue}`
          nftData.value.new_contract_address = `${contractData.address}`
          nftData.value.hash = `${tx.hash}`
          loading.value = false
          message.success('MudrasSuccess')
        }
      } catch (error) {
        showTost.value = false
        loading.value = false
        message.error(error.message || error)
      }
    }

    const getNft = () => {
      if (!searchValue.value && !address.value) return
      let add = searchValue.value
      busy.value = true
      nftData.value = {}
      showSearchLoading.value = true
      if (!add) add = address.value
      console.log(network.value, network.value.chainId)
      if (selectValue.value == 1) {
        getNfts({address: add, next: next.value, page_size: pageSize.value, chain: network.value.chainId == 1 ? 'mainnet' : 'rinkeby'}).then(res => {
          console.log(res)
          nfts.value = res.nfts
          next.value = res.next
          showSearchLoading.value = false
          busy.value = res.next ? false : true
          if (!searchValue.value) {
            nftsOfMe.value = true
          } else {
            nftsOfMe.value = false
            resultValue.value = searchValue.value
            router.push({ query: { wallet: searchValue.value } })
          }
        }).catch(err => {
          console.log(err)
          showSearchLoading.value = false
        })
      } else if (selectValue.value == 2) {
        getContract({address: add, next: next.value, page_size: pageSize.value, chain: network.value.chainId == 1 ? 'mainnet' : 'rinkeby'}).then(res => {
          console.log(res)
          nfts.value = res.nfts
          next.value = res.next
          showSearchLoading.value = false
          busy.value = res.next ? false : true
          if (!searchValue.value) nftsOfMe.value = true
          else {
            nftsOfMe.value = false
            resultValue.value = searchValue.value
            router.push({ query: { contract: searchValue.value } })
          }
        }).catch(err => {
          console.log(err)
          showSearchLoading.value = false
        })
      }
    }

    watch(address, (val) => {
      console.log(val)
      if (val) {
        getNft()
      }
    }, {immediate: true})

    watch(network, (val) => {
      console.log(val)
      if (address.value) {
        // nfts.value = []
        getNft()
      }
    })

    return {
      copy,
      kakashi,
      selectNft,
      getNft,
      filterNfts,
      loading,
      searchValue,
      nfts,
      contract,
      nftData,
      formatAddress,
      formatTokenId,
      showSearchLoading,
      showTost,
      switchValue,
      nftsOfMe,
      busy,
      next,
      selectValue,
      resultValue,
      selectOptions: ref([{
        label: "search address",
        value: 1
      }, {
        label: "search contract",
        value: 2
      }])
    }
  },
  data() {
    return {
      // nfts: []
    }
  },
  created() {
    // this.getNft()
  },
  methods: {
    
  },
}
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  font-size: 14px;
  max-width: 1440px;
  padding: 0 24px;
  box-sizing: border-box;
  margin: auto;
  .content {
    width: 1280px;
    margin: auto;
    height: 100vh;
    overflow: hidden;
    padding-top: 72px;
    box-sizing: border-box;
    .hd {
      width: 100%;
      padding-top: 140px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 100%;
        height: auto;
      }
    }
    .bd {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 40px;
      flex: 1;
      .bd-l {
        flex: 1;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        .loading-search {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(255, 255, 255, .1);
          z-index: 9;
          display: flex;
          justify-content: center;
          padding-top: 100px;
          box-sizing: border-box;
          .lds-default {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
          }
          .lds-default div {
            position: absolute;
            width: 6px;
            height: 6px;
            background: #18a058;
            border-radius: 50%;
            animation: lds-default 1.2s linear infinite;
          }
          .lds-default div:nth-child(1) {
            animation-delay: 0s;
            top: 37px;
            left: 66px;
          }
          .lds-default div:nth-child(2) {
            animation-delay: -0.1s;
            top: 22px;
            left: 62px;
          }
          .lds-default div:nth-child(3) {
            animation-delay: -0.2s;
            top: 11px;
            left: 52px;
          }
          .lds-default div:nth-child(4) {
            animation-delay: -0.3s;
            top: 7px;
            left: 37px;
          }
          .lds-default div:nth-child(5) {
            animation-delay: -0.4s;
            top: 11px;
            left: 22px;
          }
          .lds-default div:nth-child(6) {
            animation-delay: -0.5s;
            top: 22px;
            left: 11px;
          }
          .lds-default div:nth-child(7) {
            animation-delay: -0.6s;
            top: 37px;
            left: 7px;
          }
          .lds-default div:nth-child(8) {
            animation-delay: -0.7s;
            top: 52px;
            left: 11px;
          }
          .lds-default div:nth-child(9) {
            animation-delay: -0.8s;
            top: 62px;
            left: 22px;
          }
          .lds-default div:nth-child(10) {
            animation-delay: -0.9s;
            top: 66px;
            left: 37px;
          }
          .lds-default div:nth-child(11) {
            animation-delay: -1s;
            top: 62px;
            left: 52px;
          }
          .lds-default div:nth-child(12) {
            animation-delay: -1.1s;
            top: 52px;
            left: 62px;
          }
          @keyframes lds-default {
            0%, 20%, 80%, 100% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.5);
            }
          }
        }
        .nft-title {
          font-size: 18px;
          font-weight: 600;
          line-height: 1.4;
          margin-top: 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .nft-switch {
            align-items: center;
            display: flex;
            span {
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
        .nfts-wrap {
          // height: 680px;
          overflow: auto;
          flex: 1;
        }
        .nfts {
          margin-top: 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          .nft-item {
            width: 150px;
            height: 150px;
            margin-bottom: 20px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              cursor: pointer;
            }
          }
        }
      }
      .bd-r {
        padding-left: 120px;
        box-sizing: border-box;
        position: sticky;
        top: 100px;
        .bd-r-hd {
          width: 320px;
          height: 198px;
          overflow: hidden;
          img {
            width: 320px;
            height: auto;
            object-fit: cover;
          }
        }
        .bd-r-bd {
          display: flex;
          align-items: flex-start;
          margin-top: 12px;
          img {
            width: 100px;
            height: 100px;
            object-fit: cover;
          }
          .bd-r-bd-r {
            margin-left: 10px;
            p {
              margin-bottom: 14px;
              display: flex;
              align-items: center;
              svg {
                margin-left: 8px;
                width: 18px;
                height: auto;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .tost-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: rgba(255, 255, 255, .7);
    .tost-card {
      background: #fff;
      border-radius: 10px;
      padding: 20px;
      box-sizing: border-box;
      border: 1px solid rgb(224, 224, 230);
    }
    .tost-card-hd {
      width: 320px;
      height: 220px;
      overflow: hidden;
      border-radius: 5px;
      .tost-card-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 14px;
      }
      img {
        width: 320px;
        height: auto;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    .tost-card-bd {
      display: flex;
      align-items: flex-start;
      margin-top: 12px;
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
      .tost-card-bd-r {
        margin-left: 10px;
        p {
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          svg {
            margin-left: 8px;
            width: 18px;
            height: auto;
            cursor: pointer;
          }
        }
      }
    }
  }
  .link {
    margin-top: 120px;
    padding-bottom: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    .link-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      svg {
        margin-left: 8px;
      }
    }
  }
}
</style>
