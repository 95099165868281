<template>
  <n-config-provider >
    <n-message-provider>
      <Nav />
      <router-view/>
    </n-message-provider>
  </n-config-provider>
</template>
<script>
/* eslint-disable */
import { defineComponent } from 'vue'
import { darkTheme } from 'naive-ui'
import Nav from './components/Nav.vue'
export default defineComponent({
  components: {Nav},
  setup() {
    return {
      darkTheme,
    }
  },
  async mounted() {
    this.$store.commit("getWeb3")
    this.fn()
  },
  methods: {
    fn() {
      ethereum.on("accountsChanged", (accounts) => {
        console.log("账号切换", accounts[0])
        this.$store.commit("getWeb3");
      })
      ethereum.on("chainChanged", (chainId) => {
        console.log("用户切换了链", chainId);
        this.$store.commit('getWeb3')
      });
    },
  }
})
</script>
<style lang="scss">

</style>
